/* Data Table Styles */

.data-table-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  padding-top: 80px; /* para que baje la web por el menu de admin*/
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: calc(var(--vh, 1vh) * 100 - 100px); /* Usando la variable --vh */
}

.data-table-content {
  flex: 1;
  overflow-y: auto;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
}

.table-title {
  display: flex;
  align-items: center;
  font-size: 24px; /* Ajusta el tamaño de la fuente si lo necesitas */
  font-weight: bold;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.marca {
  padding: 5px 10px;
  font-size: 30px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: Jost, sans-serif;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  margin: 0px;
}

.guion {
  padding: 5px 10px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: Jost, sans-serif;
  box-sizing: border-box;
  text-decoration: none;
  margin: 0px;
}

.producto {
  padding: 5px 10px;
  font-size: 30px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0px;
}

.table-controls {
  display: flex;
  align-items: flex-start;
}

.cart-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.cart-buttonText {
  margin-right: 10px;
}

.cart-icon-image-cambiante {
  width: 24px; /* Ajusta el tamaño del ícono del carrito si es necesario */
  height: 24px;
}
.table-controls button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #c5bfc3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.table-controls button:hover {
  background-color: #6bc1e9;
}

/*div-filtros*/
.div-filtros {
  display: flex;
  align-items: center;
}

.hidden {
  display: none;
}

.div-filtros button {
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #c5bfc3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.div-filtros button:hover {
  background-color: #6bc1e9;
}

/* Active Filters */
.active-filters {
  display: flex;
  padding: 5px;
  background-color: #f9f8fa;
  border-radius: 5px;
}

.active-filters p {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 1rem;
  color: #333;
}

.filter-tag {
  display: inside;
  background-color: #e9ecef;
  padding: 0px 5px;
  margin-right: 5px;
  border-radius: 3px;
  font-size: 0.8rem;
}

.filter-tag button {
  background: none;
  border: none;
  cursor: pointer;
  color: #dc3545;
  background-color: #aac3d888;
  font-weight: bold;
}

/*controles de agrandar y achicar tamaño del texto*/
.font-size-controls {
  display: none;
  align-items: center; /* Alinea verticalmente los elementos en el centro */
  justify-content: flex-end; /* Alinea horizontalmente los elementos hacia la derecha */
}

.font-size-controls button {
  margin-left: 5px;
  background-color: #c5bfc3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.font-size-controls button:hover {
  background-color: #6bc1e9;
}

/* Table Styles */
.table-wrapper {
  flex: 1;
  overflow: auto;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

/* Estilo para las celdas del encabezado de la tabla */
thead th {
  position: sticky;
  top: 0; /* Fija el encabezado al principio del scroll */
  background-color: #f8f9fa; /* Asegura que el fondo del encabezado sea visible */
  z-index: 10; /* Eleva el nivel del encabezado para que quede por encima de las filas */
  padding: 10px;
}

td {
  border: 1px solid #dee2e6;
  padding: 12px 8px;
  text-align: left;
}

th {
  border: 1px solid #dee2e6;
  padding: 12px 8px;
  text-align: left;
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  z-index: 10;
  font-weight: bold;
}

.column-header {
  position: relative;
  padding-top: 20px; /* Ajusta este valor según sea necesario */
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  padding: 3px;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 40px;
}

.column-header:hover {
  background-color: #e9ecef;
}

.sort-indicator {
  margin-left: 5px;
}

.filter-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.filter-input {
  width: 90%;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

/*para achicar la columna id*/
.id-column {
  width: min-content;
  white-space: nowrap;
  font-size: 0.7em;
}

.id-column .filter-container {
  display: none;
  justify-content: center;
  margin-top: 5px;
}

.image-cell {
  width: 60px;
  height: 60px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

tbody tr:hover {
  background-color: #efd8fa;
}

.highlight-column {
  background-color: #fff3cd;
}

.tester-text {
  color: #17a2b8;
  font-weight: bold;
}

/*mensaje  flotante en columas destacadas*/
.frasco-especial {
  color: #ff69b4;
  font-size: 0.7em;
  vertical-align: super;
  margin-left: 2px;
  white-space: nowrap;
}

.floating-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ff69b4;
  color: white;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 0.8em;
  white-space: nowrap;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Flecha para el texto flotante */
.floating-text::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ff69b4;
}

/* miniFooter */
.miniFooter {
  display: flex;
  justify-content: space-around; /* Espacia los enlaces de manera uniforme */
  align-items: center;
  padding: 5px;
}

.miniFooter-Links {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 10px; /* Espacio entre los enlaces */
  color: #000; /* Color del texto */
}

.miniFooter-desarrollador {
  display: flex; /* Usar flexbox para centrar el contenido */
  justify-content: center; /* Centra el contenido horizontalmente */
  padding: 0 10px; /* Espaciado superior e inferior */
}

.miniFooter-desarrollador p {
  text-align: center; /* Asegura que el texto esté centrado */
  font-size: 10px; /* Tamaño de fuente más pequeño */
  margin: 0 10px; /* Espacio entre los enlaces */
  color: #000; /* Color del texto */
  padding: 0;
}

/*para los botones del agregar al carrito*/

/*icono de ver carrito, para que los efectos ocurran hacia alla*/
.cart-button {
  right: 50px;
  height: 50px;
}

/* carita al lado del carro de compras*/
.cart-icon-image-cambiante {
  display: flex;
  width: 40px; /* Ancho del ícono */
  height: 40px; /* Altura del ícono */
}

.cart-butonText {
  display: flex;
  padding: 5px;
}

.add-toCart-button {
  background-color: transparent; /* Fondo transparente */
  border: none; /* Sin borde */
  cursor: pointer; /* Cambia el cursor a una mano */
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease; /* Efecto suave para transformaciones */
}

.add-cart-icon {
  width: 20px; /* Ancho del ícono */
  height: 20px; /* Altura del ícono */
  object-fit: cover; /* Ajuste de imagen */
}

/* Efecto cuando se pasa el mouse sobre el botón */
.add-toCart-button:hover {
  transform: scale(1.4); /* Aumenta el tamaño al 120% */
}

/* Efecto cuando el botón es presionado */
.add-toCart-button:active {
  transform: scale(2); /* Disminuye el tamaño al 90% al hacer clic */
}

/*para pop up imagenes*/
.customModalContent {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  margin: auto;
  position: relative;
  z-index: 1001; /* Asegura que el modal esté por encima de otros elementos */
}

.customModalContent img {
  max-width: 640px;
  max-height: 480px;
  width: auto; /* Mantiene la proporción de la imagen */
  height: auto; /* Mantiene la proporción de la imagen */
  display: block; /* Centra la imagen */
  margin: 0 auto; /* Centra la imagen */
}

.customModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Fondo oscuro, debe estar justo debajo del contenido del modal */
}

.close-button {
  background-color: transparent; /* Sin fondo */
  border: none; /* Sin borde */
  position: absolute; /* Posición absoluta dentro del modal */
  top: 10px; /* Ajusta la posición superior */
  right: 10px; /* Ajusta la posición derecha */
  cursor: pointer; /* Cambia el cursor a una mano al pasar por encima */
  padding: 0;
  z-index: 1003;
}

.close-button img {
  width: 24px; /* Ajusta según tus necesidades */
  height: 24px;
}

/* Nuevo estilo para la etiqueta de descuento */
/*para achicar la columna id*/
.descuento-column {
  width: min-content;
  white-space: nowrap;
}

.discount-cell-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.discount-tag {
  min-width: 80px;
  background-color: rgb(206, 26, 26);/*#28a745;*/
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  text-align: center;
}

.discount-percent {
  font-size: 1.4em;
  line-height: 1;
}

.discount-text {
  font-size: 0.8em;
  line-height: 1;
}

/* Estilos para la fila de pie de tabla */
/* Contenedor para los dos cuadros */
.table-footer-dual-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  gap: 20px; /* Espacio entre los cuadros */
}

/* Cada cuadro individual */
.table-footer-box {
  flex: 1;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 15px;
  max-width: 500px; /* Ajusta según necesites */
}

.table-footer-content {
  text-align: center;
}

.footer-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 2px solid #dee2e6;
  padding-bottom: 8px;
}

.table-footer-content p {
  margin: 8px 0;
  line-height: 1;
  color: #666;
}

/* Asegurarse de que la última fila ocupe todo el ancho */
.table-footer-row td {
  padding: 0 !important;
}

/* Contenedor principal de precios */
.price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 4px;
}

/* Contenedor para apilar los precios */
.price-stack {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* Precio original (tachado) */
.real-price {
  text-decoration: line-through;
  color: #999;
  font-size: 0.85em;
  position: relative;
  display: block;
}

/* Precio final */
.final-price {
  color: #000;
  display: block;
}

/* Estilos para cuando hay descuento */
.real-price::after {
  content: '';
  position: absolute;
  left: 0;
  right: 1px;
  top: 50%;
  border-bottom: 1px solid #ff0000;
  transform: rotate(-10deg);
}

/* Ajustes para el botón de agregar al carrito cuando hay precios con descuento */

/* Ajustes para las celdas de precio */
td[class*="valor"] div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 4px;
  gap: 8px;
}

/* Estilo para precio con descuento */
.price-with-discount {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

/* Ajuste del espacio cuando hay descuento */
.price-with-discount .real-price {
  opacity: 0.7;
}

/* Animación suave para los precios al hacer hover */
.price-with-discount:hover .real-price {
  opacity: 1;
}

/* Ajuste para el responsive en móviles */
@media (max-width: 768px) {
  .price-container {
    flex-direction: row;
    align-items: center;
  }

  .price-stack {
    gap: 2px;
  }

  .real-price {
    font-size: 0.8em;
  }

  .final-price {
    font-size: 1em;
  }

  .discount-tag {
    min-width: 30px;
  }
}
