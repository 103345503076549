/* Header Styles */
#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px; /* Ajusta este valor según la altura real de tu header */
  background-color: #d7acc6;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 1000;
  transition: all 0.5s;
}

#header .container {
  /*max-width: 1200px;*/
  margin: 0 auto;
  padding: 10px 20px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-menu {
  display: flex;
  align-items: center;
}

#header .logo {
  margin-right: 20px;
}

#header .logo img {
  height: 40px;
  width: auto;
}

.menu-toggle {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.menu-toggle span {
  margin-left: 5px;
}

#header .titulo {
  flex-grow: 1;
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-size: 24px;
  color: #333333;
  margin: 0;
}

.auth-section {
  display: flex;
  align-items: center;
}

.auth-button {
  padding: 8px 20px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #f0bedc;
  font-weight: 600;
  background: #f0bedc;
  transition: 0.3s;
  min-width: 120px;
  cursor: pointer;
}

.auth-button:hover {
  background-color: #e0aece;
  border-color: #e0aece;
}

/* Navigation Menu */
.menu.navbar {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.menu.navbar.show {
  display: block;
}

.menu.navbar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu.navbar li {
  padding: 0;
}

.menu.navbar a {
  display: block;
  padding: 12px 20px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
}

.menu.navbar a:hover {
  background-color: #e9ecef;
}



/* opciones de menu sin logueo */
.menu-container {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  gap: 20px; /* Ajusta el espacio entre los elementos como desees */
}

.nav-link {
  text-decoration: none;
  color: #000; /* Ajusta el color del enlace como desees */
}

.nav-link:hover {
  text-decoration: underline;
}





