/* Responsive Design */

@media (max-width: 768px) {

  #header .container {
    padding: 10px;
  }

  #header .titulo {
    font-size: 20px;
  }

  .auth-button {
    font-size: 12px;
    padding: 6px 12px;
    min-width: 100px;
  }

  .menu-toggle {
    font-size: 14px;
  }
  .marca {
    display: none;
  }
  
  .guion {
    display: none;
  }

  .floating-text {
    position: static;
    display: block;
    margin-bottom: 2px;
  }

  .floating-text::after {
    display: none;
  }

  /* pop ups de imagenes*/
  .customModalContent {
    max-width: 80%; /* Aumenta el ancho para pantallas pequeñas */
    padding: 10px; /* Reduce el padding para ahorrar espacio */
  }

  .customModalContent img {
      max-width: 100%; /* Asegúrate de que la imagen no se desborde en pantallas pequeñas */
      max-height: 80vh; /* Limita la altura de la imagen para evitar que se salga de la pantalla */
  }

  .close-button img {
      width: 20px; /* Reduce el tamaño del ícono en móviles */
      height: 20px;
  }




}

/********* tipo celular ************/

@media (max-width: 576px) {
  .App {
    padding-top: 70px; /* Ajusta esto si cambiaste la altura del header para pantallas pequeñas */
  }

  #header {
    height: 70px; /* Ajusta la altura para pantallas más pequeñas si es necesario */
    
  }

  #header .container {
    padding-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
  }

  .header-content {
    flex-wrap: wrap;
  }

  #header .titulo {
    order: -1;
    width: 100%;
    margin-bottom: 1px;
    font-size: 18px;
  }

  .logo-menu {
    order: 1;
  }

  .auth-section {
    order: 2;
    margin-top: 1px;
  }

  .app-main {
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
  }

  .app-main {
    padding-top: 6px;
    padding-bottom: 40px;
    padding-left: 8px;
    padding-right: 8px;
    
  }

  .marca {
    display: none;
  }
  
  .guion {
    display: none;
  }
  
  .producto {
    padding: 5px 10px;
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 0px;
  }


  .table-header {
    align-items: flex-start;
    margin-bottom: 5px;
  }

  .table-title {
    font-size: 18px;
  }

  .table-controls {
    margin-top: 1px;
  }

  .table-controls button {
    padding: 1px 1px;
    font-size: 14px;
  }

  .filter-input {
    width: 100%;
  }

  .column-header {
    font-size: 10px;
  }

  .table-wrapper th {
    padding-bottom: 3px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 0px;
  }

  .data-table-container {
    padding-bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .table-wrapper tbody {
    font-size: 10px;
  }

  .table-wrapper td {
    padding-bottom: 3px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
  }

  .floating-text {
    position: static;
    display: block;
    margin-bottom: 2px;
  }

  .floating-text::after {
    display: none;
  }

  #tblcarrito {
    font-size: 12px;
  }

  .cart-icon {
    /*position: fixed;*/ /* Para que el carrito esté siempre visible en la esquina */
    right: 15px; 
    /*width: 500px; */
    height: 25px;
  }

  /* carita al lado del carro de compras*/
  .cart-icon-image {
    /*position: fixed;*/ /* Para que el carrito esté siempre visible en la esquina */
    /*right: 130px; */
    width: 25px; /* Ancho del ícono */
    height: 25px; /* Altura del ícono */
  }

  /*filtros*/

  .div-filtros button {
    margin-left: 1px;
    margin-right: 3px;
    padding: 3px 6px;
  }

  .active-filters {
    margin-top: 1px;
    margin-bottom :1px;
    padding-bottom :1px;
    padding-left :1px;
    padding-right :1px;
    padding-top :1px;
  }

  .active-filters span{
    font-size: 10px;
  }

  .active-filters p {
    display: none;
  }

  .filter-tag {
    padding: 0px 2px;
    margin-right: 3px;
    border-radius: 3px;
    font-size: 0.8rem;
  }
  
  .filter-tag button {
    margin: 3px;
    padding: 4px 8px;
    cursor: pointer;
    color: #dc3545;
    background-color: #aac3d888;
    font-weight: bold;
  }

  /*de carrito*/
  .cart-delete-button {
    width: 15px; /* Tamaño ancho del boton del basurero del carrito */
  }

/*mini footer*/
/*
.miniFooter {
  flex-direction: column;
  align-items: center;
}
  */
.miniFooter-Links {
  margin: 2px 0; /* Ajusta el espacio entre los enlaces en pantallas pequeñas */
}

.miniFooter-Texto {
  display:none;
}


}

@media (max-width: 350px) {
  #tblcarrito {
    font-size: 8px;
  }
}



/********* CELULAR A LO ANCHO **********/

@media (max-height: 600px) {
  .App {
    padding-top: 45px; /* Ajusta esto si cambiaste la altura del header para pantallas pequeñas */
  }

  #header {
    height: 45px; /* Ajusta la altura para pantallas más pequeñas si es necesario */
  }

  #header .container {
    padding-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
  }

  #header .titulo {
    width: 100%;
    margin-bottom: 1px;
    font-size: 18px;
  }

  .auth-section {
    margin-top: 1px;
  }

  .auth-button {
    font-size: 10px;
    padding: 6px 12px;
    min-width: 100px;
  }

  .app-main {
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
  }

  .table-header {
    align-items: flex-start;
    margin-bottom: 5px;
  }

  .table-title {
    font-size: 18px;
  }

  .table-controls {
    margin-top: 1px;
  }

  .active-filters {
    margin-top: 1px;
    margin-bottom :1px;
    padding-bottom :5px;
    padding-left :5px;
    padding-right :5px;
    padding-top :5px;
  }

  .active-filters h3{
    font-size: 12px;
    margin-bottom: 2px;
  }

  .active-filters span{
    font-size: 10px;
  }

  .filter-input {
    width: 100%;
  }

  .column-header {
    font-size: 10px;
    height: 25px;
  }

  .table-wrapper th {
    padding-bottom: 3px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 0px;
  }

  .data-table-container {
    padding-bottom: 20px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .table-wrapper tbody {
    font-size: 10px;
  }

  .table-wrapper td {
    padding-bottom: 3px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
  }


  
  /* pop ups de imagenes*/
  .customModalContent {
    max-width: 80%; /* Aumenta el ancho para pantallas pequeñas */
    padding: 10px; /* Reduce el padding para ahorrar espacio */
  }

  .customModalContent img {
      max-width: 100%; /* Asegúrate de que la imagen no se desborde en pantallas pequeñas */
      max-height: 80vh; /* Limita la altura de la imagen para evitar que se salga de la pantalla */
  }

  .close-button img {
      width: 20px; /* Reduce el tamaño del ícono en móviles */
      height: 20px;
  }


}
