/* Login Form Container */
.login-form-container {
  position: absolute;
  right: 20px;
  top: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
  background-color: #ffffff;
  padding: 20px;
  width: 200px;
  height: 200px;
  margin: 20px auto;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-form-group {
  margin-bottom: 5px;
}

.login-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.login-form input {
  width: 95%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.login-form input:focus {
  outline: none;
  border-color: #f0bedc;
  box-shadow: 0 0 0 2px rgba(240, 190, 220, 0.2);
}

.login-form-error {
  color: #d9534f;
  margin-top: 5px;
  font-size: 14px;
}

.login-form-submit {
  background-color: #f0bedc;
  color: white;
  border: none;
  padding: 5px 5px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form-submit:hover {
  background-color: #e0aece;
}

.login-form-submit:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(240, 190, 220, 0.5);
}
